<template>
  <div>
    <el-card>
      <el-row>
        <el-col>
          <div>
            <el-button
              type="primary"
              size="medium"
              class="demo-input-label"
              icon="el-icon-search"
              @click="searchClick"
              >搜索</el-button
            >

            <el-button
              type="primary"
              size="medium"
              class="demo-input-label"
              icon="el-icon-plus"
              @click="addInfoClick()"
              >添加</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-card>
    <div>
      <el-table :data="records" border style="width: 98%" :height="innerHeight">
        <el-table-column type="index" width="50">
          <template slot="header" slot-scope="{}"> 序号 </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="活动模板名称"
          width="150"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="tenantName"
          label="公司名称"
          width="150"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="活动类型"
          width="150"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="updateClick(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="deleteInfoClick(scope.row)"
              >删除</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="examineInfoClick(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <!-- 分页区域 -->

      <pagination
        :pagingParams="params"
        :total="total"
        @pagingSizeChange="handleSizeChange"
        @pagingNumChange="handleCurrentChange"
      ></pagination>
    </div>
    <!-- 修改用户的对话框 -->
    <el-dialog
      title="订单修改"
      :visible.sync="isDialog"
      width="50%"
      @close="isDialog = false"
    >
      <el-form :model="saveInfoParams" ref="editFormRef" label-width="70px">
        <el-form-item label="模板名称">
          <el-input
            v-model="saveInfoParams.name"
            placeholder="请输入模板名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="模板类型">
          <el-input
            v-model="saveInfoParams.type"
            placeholder="请输入模板类型"
            type="Number"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述信息">
          <el-input
            v-model="saveInfoParams.description"
            placeholder="description"
          ></el-input>
        </el-form-item>
        <el-form-item label="imgBanner">
          <uploadImg
            @imgChage="imgChage"
            name="imgBanner"
            :imgUrl="saveInfoParams.imgBanner"
          ></uploadImg>
        </el-form-item>
        <el-form-item label="imgBg">
          <uploadImg
            @imgChage="imgChage"
            name="imgBg"
            :imgUrl="saveInfoParams.imgBg"
          ></uploadImg>
        </el-form-item>
        <el-form-item label="imgIcon">
          <uploadImg
            @imgChage="imgChage"
            name="imgIcon"
            :imgUrl="saveInfoParams.imgIcon"
          ></uploadImg>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isDialog = false">取 消</el-button>
        <el-button v-if="dialogType == 1" type="primary" @click="addInfo"
          >确 定</el-button
        >
        <el-button v-if="dialogType == 2" type="primary" @click="updateInfo"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "@/components/common/pagination/Index.vue";
import uploadImg from "@/components/common/uploadImg/Index.vue";
import { commonMixin } from "@/utils/mixin/index.js";
const saveInfoParams = {
  name: "healthIQ",
  type: "4",
  imgIcon: "",
  description: "详情",
  imgBg: "",
  imgBanner: "",
  detailIntroduce: "",
};
export default {
  name: "AdminDemoIndex",
  components: {
    pagination,
    uploadImg,
  },
  mixins: [commonMixin],
  data() {
    return {
      params: { pageNum: 1, pageSize: 10 },
      isAddInfo: false,
      saveInfoParams: {
        name: "healthIQ",
        type: "4",
        imgIcon: "",
        description: "详情",
        imgBg: "",
        imgBanner: "",
        detailIntroduce: "",
      },
      records: [],
      total: 0,
      dialogType: 1,
      isDialog: false,
    };
  },
  mounted() {
    this.findList();
  },
  methods: {
    imgChage(item) {
      this.saveInfoParams[item.name] = item.url;
    },
    async findList() {
      const res = await this.$API.template.get(this.params);
      if (res.status !== 200) return this.$message.error(res.msg);
      const { records, total } = res.data;
      this.records = records;
      this.total = +total;
    },
    // 添加
    addInfoClick() {
      this.saveInfoParams = saveInfoParams;
      this.isDialog = true;
      this.dialogType = 1;
    },
    updateClick(item) {
      this.saveInfoParams = item;
      this.isDialog = true;
      this.dialogType = 2;
    },

    async updateInfo() {
      const res = await this.$API.template.update(this.saveInfoParams);
      if (res.status !== 200) return this.$message.error(res.msg);
      this.resetFrom(res.msg);
    },
    async addInfo() {
      const res = await this.$API.template.add(this.saveInfoParams);
      if (res.status !== 200) return this.$message.error(res.msg);
      this.resetFrom(res.msg);
      this.params.pageNum = 1;
      this.findList();
    },
    // 重置from
    resetFrom(msg) {
      msg && this.$message.success(msg);
      this.saveInfoParams = saveInfoParams;
      this.$refs.editFormRef.resetFields();
      this.isDialog = false;
    },

    async deleteInfo() {
      const res = await this.$API.template.delete();
      if (res.status !== 200) return this.$message.error(res.msg);
      this.$message.success(res.data);
    },
    async deleteInfoClick(item) {
      const isTrue = await this.deleteClick();
      if (!isTrue) return "";
      this.deleteInfo(item);
    },
    async deleteInfo(item) {
      const params = {
        id: item.id,
        tenantName: item.tenantName,
      };
      const res = await this.$API.template.delete(params);
      if (res.status !== 200) return this.$message.error(res.msg);
      this.$message.success(res.data);
      this.params.pageNum = 1;
      this.findList();
    },
  },
};
</script>

<style lang="scss" scoped>
.healthIQ {
  width: 100%;
  background: #fff;
}

.el-cascader {
  width: 100%;
}

.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
.el-pagination {
  width: 1000px;
  margin: 20px auto;
}
.el-tag {
  margin: 7px;
}
</style>
